@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
    // Get the color config from the theme.
    $color-config: mat.get-color-config($theme);

    // Get the primary color palette from the color-config.
    $primary-palette: map.get($color-config, 'primary');
    $accent-palette: map.get($color-config, 'accent');
    $warn-palette: map.get($color-config, 'warn');

    .exg-color-primary {
        color: mat.get-color-from-palette($primary-palette) !important;
    }

    .exg-color-accent {
        color: mat.get-color-from-palette($accent-palette) !important;
    }

    .exg-color-warn {
        color: mat.get-color-from-palette($warn-palette) !important;
    }

    .exg-color-primary-gray {
        color: mat.get-color-from-palette($primary-palette, 50) !important;
    }

    .exg-color-primary-light {
        color: mat.get-color-from-palette($primary-palette, 100) !important;
    }

    .exg-color-accent-light {
        color: mat.get-color-from-palette($accent-palette, 100) !important;
    }

    .exg-color-warn-light {
        color: mat.get-color-from-palette($warn-palette, 100) !important;
    }

    .exg-color-primary-contrast {
        color: mat.get-color-from-palette($primary-palette, '500-contrast') !important;
    }

    .exg-color-accent-contrast {
        color: mat.get-color-from-palette($accent-palette, '500-contrast') !important;
    }

    .exg-color-warn-contrast {
        color: mat.get-color-from-palette($warn-palette, '500-contrast') !important;
    }

    .exg-background-color-primary {
        background-color: mat.get-color-from-palette($primary-palette) !important;
    }

    .exg-background-color-accent {
        background-color: mat.get-color-from-palette($accent-palette) !important;
    }

    .exg-background-color-warn {
        background-color: mat.get-color-from-palette($warn-palette) !important;
    }

    .exg-background-color-primary-light {
        background: mat.get-color-from-palette($primary-palette, 100) !important;
    }

    .exg-background-color-accent-light {
        background: mat.get-color-from-palette($accent-palette, 100) !important;
    }

    .exg-background-color-warn-light {
        background: mat.get-color-from-palette($warn-palette, 100) !important;
    }

    .exg-background-color-primary-contrast {
        background-color: mat.get-color-from-palette($primary-palette, '500-contrast') !important;
    }

    .exg-background-color-accent-contrast {
        background-color: mat.get-color-from-palette($accent-palette, '500-contrast') !important;
    }

    .exg-background-color-warn-contrast {
        background-color: mat.get-color-from-palette($warn-palette, '500-contrast') !important;
    }

    .exg-border-color-primary {
        border-color: mat.get-color-from-palette($primary-palette) !important;
    }

    .exg-border-color-primary-light {
        border-color: mat.get-color-from-palette($primary-palette, 100) !important;
    }

    .exg-border-color-accent {
        border-color: mat.get-color-from-palette($accent-palette) !important;
    }

    .exg-border-color-warn {
        border-color: mat.get-color-from-palette($warn-palette) !important;
    }
}
