@import "reset.scss";
@import "roboto-fontface/css/roboto/roboto-fontface.css";
@import "variables.scss";
@import "material-theme-exg.scss";
@import "helpers.scss";
@import "dialog-styles.scss";
@import "snackbar-styles.scss";
@import "common-controls.scss";

/* set only very generic styles for the whole site -- START */

html {
    letter-spacing: 0.3px;
    font-size: 10px;
    background-color: #fafafa;

    * {
        box-sizing: border-box;
    }

    @media (max-width: $mediaDesktop) {
        font-size: 9px;
    }

    @media (max-width: $mediaMobile) {
        font-size: 9px;
    }
}

body {
    height: 100%;
    font: 400 1.4rem / 1.33 Roboto;
    letter-spacing: 0.5px;
    color: #181818;
    overflow-y: scroll;
}

input, textarea, select, button {
    font: 400 1.4rem / 1.33 Roboto;
}

p, span, i {
    color: #181818;
    letter-spacing: 0;
}

a {
    cursor: pointer;
}

h1, h2 {
    color: $accent;
    font: 700 2rem / 1.17 Roboto;
    letter-spacing: 0;
}

h3 {
    color: $accent;
    font: 500 2rem / 1.3 Roboto;
    letter-spacing: 0;
}

h4 {
    color: $accent;
    font: 500 1.6rem / 1.3 Roboto;
    letter-spacing: 0.5px;
}

b {
    font-weight: 500;
}

.timepicker-backdrop-overlay {
    z-index: 1001 !important;
}

.timepicker-overlay {
    z-index: 1002 !important;
}

.mat-mdc-autocomplete-panel {
    background-color: #fff !important;
}

.mat-mdc-select-panel {
    background-color: #fff !important;
}

/* set only very generic styles for the whole site -- END */
