@use 'sass:map';
@use '@angular/material' as mat;

$hover-opacity: 0.2;

@mixin color($theme) {
    // Get the color config from the theme.
    $color-config: mat.get-color-config($theme);

    // Get the primary color palette from the color-config.
    $primary-palette: map.get($color-config, 'primary');
    $accent-palette: map.get($color-config, 'accent');
    $warn-palette: map.get($color-config, 'warn');

    .ye-analytics-info-primary {
        background-color: rgba(mat.get-color-from-palette($primary-palette), $hover-opacity);
    }

    .ye-analytics-info-accent {
        background-color: rgba(mat.get-color-from-palette($accent-palette), $hover-opacity);
    }

    .ye-analytics-info-warn {
        background-color: rgba(mat.get-color-from-palette($warn-palette), $hover-opacity);
    }
}
